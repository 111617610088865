
<!-- Loader -->
    <div class="page-loader" *ngIf="desktop == true">
        <div class="page-loader-inner">
            <img src="assets/images/loader-shadow.png" class="loader-shadow" alt="loader shadow">
            <img src="assets/images/loader-inner.png" class="loader-inner" alt="loader inner">
            <img src="assets/images/loader-outer.png" class="loader-outer" alt="loader outer">
            <img src="assets/images/logo-red.png" class="loader-logo" alt="logo red" >
        </div>
    </div>
    <!-- Loader End -->
    
    <header class="main-header">
        
        <div class="main-nav">
            <div class="container position-relative">
                <nav class="row navbar navbar-expand-lg navbar-light">
                  <a class="navbar-brand" href=""><img src="{{ companyLogo  }}" class="main-logo-red" alt="STUDDS Accessories Private Limited"></a>
                  <a class="mobile-search-trigger" id="formTrigger" href="javascript:void(0)" (click)="openSearchForm()"><i class="fa fa-search"></i></a>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item d-none d-lg-block">
                        <a class="nav-link mega-trigger" data-target="prod-mega" href="javascript:void(0)">PRODUCTS</a>
                      </li>
                      <li class="nav-item dropdown d-block d-lg-none">
                          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                            PRODUCTS
                          </a>
                          <ul class="dropdown-menu mega-dropdown ml-3">
                            <ng-container *ngFor="let mprod of category; let i=index">
                              <li class="nv-item dropdown">
                                <a class="nav-link" [href]="mprod.mainurl" >{{mprod.name}}</a>
                                <i class="second-trigger fa fa-chevron-down" (click)="customLevel(i)"></i>
                                <ul class="dropdown-menu" id="customdropdown{{i}}">
                                  <li *ngFor="let cat of mprod.category"><a class="dropdown-item" href="{{mprod.mainurl}}/{{cat.catnameurl}}">{{ cat.name }}</a></li>
                                </ul>
                              </li>
                            </ng-container>
                          </ul>
                      </li>
                      <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                            STORE LOCATOR
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="brand-outlet"><span>EXCLUSIVE BRAND OUTLET</span></a>
                            <a class="dropdown-item" href="dealers"><span>DEALERS</span></a>
                          </div>
                      </li>
                      <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                            CORPORATE
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="about"><span>OUR COMPANY</span></a>
                            <a class="dropdown-item" href="media"><span>MEDIA</span></a>
                            <a class="dropdown-item" href="awards"><span>Awards &amp; Recognitions</span></a>
                            <a class="dropdown-item" href="events"><span>Events</span></a>
                            <a class="dropdown-item" href="gallery"><span>Gallery</span></a>
                            <a class="dropdown-item" href="investor-relations"><span>INVESTOR RELATIONS</span></a>
                            <a class="dropdown-item" href="csr-activities"><span>CSR POLICIES AND ACTIVITIES</span></a>
                          </div>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" href="gallery">GALLERY</a>
                      </li> -->
                      <li class="nav-item">
                        <a class="nav-link" href="safety">SAFETY</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="blogs">BLOGS</a>
                      </li>
                      
                      <li class="nav-item">
                        <a class="nav-link" href="faq">FAQ</a>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" href="contact-us">CONTACT</a>
                      </li> -->
                      <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbardrop" data-toggle="dropdown">
                            Contact
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="contact-us"><span>REG. AND CORPORATE OFFICE</span></a>
                            <a class="dropdown-item" href="contact-us"><span>Corporate & Bulk Enquiry</span></a>
                            <a class="dropdown-item" href="manufacturing-facility"><span>MANUFACTURING FACILITY</span></a>
                          </div>
                      </li>
                      <li class="nav-item" style="margin-top: 12px;padding-left: 19px;">
                        <a target="_blank" class="shop-button" href="https://shop.studds.com/?utm_source=studds.com&utm_medium=referral&utm_campaign=shop+now+button" style="font-size: 16px;padding-right: 10px;padding-left: 10px;padding-top: 4px;padding-bottom: 4px;background: #268ca2;color: white;"><i class="fa fa-shopping-cart" style="font-size: 16px;color: white;margin-right: 7px;"></i> SHOP NOW</a>
                      </li>
                      <!-- <li class="nav-item d-none d-lg-inline-block">
                        <a class="nav-link" id="formTrigger" href="javascript:void(0)" (click)="openSearchForm()"><i class="fa fa-search"></i></a>
                      </li> -->
                    </ul>
                  </div>
                </nav>
                <!-- Mega Menu -->
                <div class="mega-menu" id="prod-mega" style="display: none;">
                    <div class="row align-items-center">
                        <div class="col-lg-2 trigger-nav-wrap">
                            <ul class="trigger-nav">
                                <li *ngFor="let item of mainCats; let i=index">
                                  <a class="category-s" [href]="item.mainurl" (mouseenter) ="switchCats(item.mainurl, $event)" [attr.data-target]="item.mainurl" [ngClass]="{'active': i==0}">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-10">
                            <img src="assets/images/ray-red.png" class="lighten-item" alt="ray red">
                            <!--Dynamic Menu Loop -->
                            <div *ngFor="let subs of category; let i=index" class="mega-showcase-wrap" id="{{subs.mainurl}}" [ngStyle]="{'display':i==0 ? 'block' : 'none'}">
                                <span class="mega-prev mega-prev1" style="opacity: 0;" (click)="headPrev()"></span>
                                <div class="mega-showcase">
                                    <a href="{{subs.mainurl}}/{{cat.catnameurl}}" (mouseenter)="moveDot($event)" class="mega-showcase-item text-center" *ngFor="let cat of subs.category">
                                        <img [src]="cat.image" class="mega-showcase-img img-fluid" *ngIf="desktop==true" width="130" height="130" alt="{{ cat.name }}">
                                        <h4 class="mega-cat-name">{{ cat.name }}</h4>
                                    </a>
                                </div>
                                <span class="mega-next mega-next1" (click)="headNext()"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-form" id="searchForm" [ngClass]="{'opened': searchOpened}">
                  <input type="text" class="search-input" #searchInput placeholder="Search your product here" (keyup)="showresults(searchInput.value)">
                  <button type="submit" class="search-submit">Search</button>
                  <ul class="search-results" [ngClass]="{'opened': resultOpened}">
                    <li *ngFor="let searched of allProducts">
                      <a [href]="searched.url">{{searched.name}}</a>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </header>