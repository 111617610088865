
    <section class="footer-sec text-center">
        <img srcset="assets/images/noimage.png 767w, assets/images/footer-bg.png 1920w" 
        src="assets/images/footer-bg.png" class="footer-bg" style="z-index: 1" alt="footer bg">
        <div class="floating-rays">
            <img srcset="assets/images/noimage.png 767w, assets/images/ray-blue.png 1920w" 
            src="assets/images/ray-blue.png" class="ray1" alt="ray1 blue">
            <img srcset="assets/images/noimage.png 767w, assets/images/ray-blue.png 1920w" 
            src="assets/images/ray-blue.png" class="ray2" alt="ray2 blue">
        </div>
        <div class="container text-white mt-0 pb-5 position-relative" style="z-index: 2">
            <h3 class="text-center quoted-text"><span>&quot;</span>{{footerQuotes}}<span>&quot;</span></h3>
            <h6 class="text-right" style="font-family: roboto">Source: {{footerQuotesBy}}</h6>
        </div>
        <div class="container position-relative" style="z-index:1">
            <img src="assets/images/footer-helmet.png" class="footer-helmet" alt="footer helmet">
            <div class="footer-newsletter">
                <h3 class="newsletter-title">Stay updated with the latest at STUDDS</h3>
                <form [formGroup]="newsletterForm" (ngSubmit)="newsletterSubmit()">
                    <div class="newsletter-form">
                            <div class="form-input" [ngClass]="{'has-error': newsletterForm.get('email').errors && (newsletterForm.get('email').touched || newsletterForm.get('email').dirty) }">
                                <input type="text" formControlName="email" placeholder="Your Email Id" class="form-control">
                                <button type="submit" class="newsletter-submit" [disabled]="!newsletterForm.valid"><i class="fa fa-chevron-right"></i></button>
                                <div class="submit-loader text-center" *ngIf="loader">
                                    <span></span>
                                </div>
                                <span style="font-family:roboto" class="help-block" *ngIf="newsletterForm.get('email').errors && newsletterForm.get('email').errors.required && (newsletterForm.get('email').touched || newsletterForm.get('email').dirty)">
                                    Email is required
                                </span>
                                <span style="font-family:roboto" class="help-block" *ngIf="newsletterForm.get('email').errors && newsletterForm.get('email').errors.email && (newsletterForm.get('email').touched || newsletterForm.get('email').dirty)">
                                    Please Enter a valid Email Id
                                </span>
                                <div *ngIf="hide==false">
                                    <div class="alert alert-success mb-5 mt-4" *ngIf="errorno==1">
                                        You have successfully subscribed for our newsletter
                                    </div>
                                    <div class="alert alert-danger mb-5 mt-4" *ngIf="formSuccess==false">
                                        Some Error occurred.
                                   </div>
                                   <div class="alert alert-danger mb-5 mt-4" *ngIf="errorno==3">
                                        Email is Already Subscribed.
                                    </div>
                                </div>
                        </div>	
                    </div>
                </form>
                
            </div>
            <ul class="footer-menu">
                <ng-container *ngFor="let link of footerLinks">
                    <li *ngIf="link=='about'"><a [href]="link">ABOUT</a></li>
                    <li *ngIf="link=='dealers'"><a [href]="link">DEALER LOCATOR</a></li>
                    <li *ngIf="link=='safety'"><a [href]="link">SAFETY</a></li>
                   
                    <li *ngIf="link=='privacy-policy'"><a [href]="link">Privacy Policy</a></li>
                    <li *ngIf="link=='media'"><a [href]="link">media</a></li>
                    <li *ngIf="link=='dafety'"><a [href]="link">Safety</a></li>
                    <li *ngIf="link=='contact-us'"><a [href]="link">Corporate Office</a></li>
                    <li *ngIf="link=='manufacturing-facility'"><a [href]="link">Manufacturing Facility</a></li>
                    <li *ngIf="link=='awards'"><a [href]="link">Awards</a></li>
                    <li *ngIf="link=='investor-relations'"><a [href]="link">Investor Relation</a></li>
                    <li *ngIf="link=='csr-activities'"><a [href]="link">CSR Policies and Activities</a></li>
                    <li *ngIf="link=='helmets'"><a [href]="link">Helmets</a></li>
                    <li *ngIf="link=='motorcycle-accessories'"><a [href]="link">Accessories</a></li>
                    <li *ngIf="link=='blogs'"><a [href]="link">Blog</a></li>
                </ng-container>
                <li><a href="sitemap.html">Sitemap</a></li>
            </ul>
            <div class="row text-center addres_tab"> 
                <div class="address_new col-lg-4 col-md-6 col-sm-12">
                    <h5 class="address_heading">Manufactured And Marketed By</h5>
                    <p class="address_pra">STUDDS Accessories Limited, Registered and Corporate Office, Plot no. 918, Sector 68, IMT, Faridabad, Haryana – 121004 (INDIA)
                        </p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 social_tab">
                    <h5 class="address_new address_heading">Social linking</h5>
                    <ul class="footer-social">
                        <ng-container *ngFor="let social of footerSocial">
                            <li *ngIf="social.type=='facebook'"><a target="_blank" [href]="social.value"><i class="fa fa-facebook"></i></a></li>
                            <li *ngIf="social.type=='twitter'"><a target="_blank" [href]="social.value"><i class="fa fa-twitter"></i></a></li>
                            <li *ngIf="social.type=='instagram'"><a target="_blank" [href]="social.value"><i class="fa fa-instagram"></i></a></li>
                            <li *ngIf="social.type=='youtube'"><a target="_blank" [href]="social.value"><i class="fa fa-youtube-play"></i></a></li>
                            <li *ngIf="social.type=='linkedin'"><a target="_blank" [href]="social.value"><i class="fa fa-linkedin"></i></a></li>
                            <li *ngIf="social.type=='whatsapp'"><a target="_blank" [href]="social.value"><i class="fa fa-whatsapp"></i></a></li>
                            <li *ngIf="social.type=='skype'"><a target="_blank" [href]="social.value"><i class="fa fa-skype"></i></a></li>
                            <li *ngIf="social.type=='snapchat'"><a target="_blank" [href]="social.value"><i class="fa fa-snapchat"></i></a></li>
                            <li *ngIf="social.type=='tumblr'"><a target="_blank" [href]="social.value"><i class="fa fa-tumblr"></i></a></li>
                            <li *ngIf="social.type=='pinterest'"><a target="_blank" [href]="social.value"><i class="fa fa-pinterest"></i></a></li>
                        </ng-container>
                    </ul>
                </div>
                <div class="address_customer col-lg-4 col-md-6 col-sm-12">
                    <h5 class="address_heading">Customer Care </h5>
                   <p class="address_pra"> CUSTOMER CARE EXECUTIVE :<br>
                    STUDDS ACCESSORIES LIMITED, PLOT NO. 918, SECTOR 68, IMT, 
                     FARIDABAD, HARYANA - 121004 (INDIA) | PHONE: <a href="tel:01294296555" style="color:#fff;">0129 - 4296555</a>
                   | EMAIL: <a href="mailto:customercare@studds.com" style="color:#fff;">CUSTOMERCARE@STUDDS.COM</a> | CIN no.: U25208HR1983PLC015135
                    
                    </p>
                </div>
            </div>

            <!-- <ul class="footer-social">
                <ng-container>
                    <li class="address_new"><h5 class="address_heading">Manufacturer And Marketed By</h5>
                    <p>STUDDS Accessories Limited, 
                    Registered and Corporate Office, 
                    Plot no. 918, Sector 68, IMT, Faridabad, 
                    Haryana – 121004 (INDIA)</p></li>
                    <li class="address_customer"><h5 class="address_heading">Customer Care Executive</h5>
                        customercare@studds.com <br/>
                        0129-4296555
                    </li>
                </ng-container>
            </ul> -->
        </div>
        <div class="footer-copyrights zindex1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                    <p>{{footerCopyrights}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <a href="javascript:void(0)" class="gototop">
        <i class="fa fa-chevron-up"></i>
    </a>
    <div class="main-nav blink1">
            <div class="container position-relative">
                <a style="color:white" href="https://shop.studds.com/?utm_source=Studds.com+Stickey&utm_medium=Referral&utm_campaign=sticky+footer" target="_blank">Shop Our Products @ <b style="text-transform: capitalize;">Shop.Studds.com</b> Click Here!<span class="blink">&nbsp;<i class="fa fa-hand-pointer-o" aria-hidden="true"></i></span></a>
            </div>
        </div>

        <button id="Div1" class="vid-trigger23" style="z-index: 99;">
            <svg height="64" width="140" style="z-index: -1;">
                <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
            </svg>
            <img src="assets/images/Studds_icons_1.png" style="z-index:88;padding-right: 128px;background: #000000;border-radius: 27px 10px 10px 27px;" onclick="switchVisible();"><span><a href="https://www.studds.com/assets/product-catalogue-2024.pdf" target="_blank" style="color:white;"><i class="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;Catalogue</a></span>
        </button>
        <button id="Div2" class="vid-trigger23">
            
            <img src="assets/images/Studds_icons_1.png" style="z-index:88;" onclick="switchVisible();"><span>Exit</span>
        </button>